.banner {
    margin-top: 7.5em;
    @include breakpoint($celular) {
        margin-top: 6.5em;
    }

    .carousel-cell {
        position: relative;
        width: 100%;

        img {
            width: 100%;
        }

        .gradient {
            position: absolute;
            width: 100%;
            height: 99%;
            top: 0;

            .container {
                background: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.8) 0%,
                    rgba(255, 255, 255, 0) 50%
                );
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                box-sizing: border-box;
                font-size: 1.5em;
                text-transform: uppercase;
                text-align: right;
                color: rgb(175, 155, 123);
                padding-bottom: 1em;
                @include breakpoint($tablet) {
                    font-size: 1.3em;
                }
                @include breakpoint($celular) {
                    font-size: 1.2em;
                }

                .texto {
                    display: flex;
                    flex-direction: column;
                    width: 70%;
                }
            }
        }
    }
}
