.equipe {
    flex-direction: column;
    align-items: center;

    .container-texto {
        background: url("../img/bgsection.png") #fff 95% 90% no-repeat;
        align-items: center;
        min-height: 35em;
        @include breakpoint($celular) {
            padding: 2em 0;
        }

        .texto {
            color: rgb(95, 95, 83);
            text-align: justify;
            width: 70%;
            line-height: 1.8em;

            h1 {
                margin: 1em 0 1.5em;
                text-transform: uppercase;
                font-weight: normal;
                text-align: left;

                small {
                    text-transform: none;
                    font-size: 0.5em;
                }
            }
        }
    }

    .servicos {
        display: flex;
        justify-content: center;
        background-color: rgb(137, 137, 126);
        padding: 3em 0;

        .content {
            width: 70%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            @include breakpoint($tablet) {
                grid-template-columns: repeat(3, 1fr);
            }
            @include breakpoint($celular) {
                grid-template-columns: 1fr;
            }

            .servico {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: rgb(198, 198, 190);
                text-transform: uppercase;
                font-weight: bold;
                text-align: center;
                background: url("../img/divider-equipe.png") right center
                    no-repeat;
                padding: 0 1em;

                @include breakpoint($full) {
                    &:nth-child(4n),
                    &:last-child {
                        background: none;
                        padding: 0 0 0 1em;
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        margin-bottom: 2em;
                    }
                }
                @include breakpoint($monitor) {
                    &:nth-child(4n),
                    &:last-child {
                        background: none;
                        padding: 0 0 0 1em;
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        margin-bottom: 2em;
                    }
                }
                @include breakpoint($tablet) {
                    &:nth-child(3n),
                    &:last-child {
                        background: none;
                        padding: 0 0 0 1em;
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                        margin-bottom: 2em;
                    }
                }
                @include breakpoint($celular) {
                    padding: 1em 0;
                    background: url("../img/divider-equipe_mob.png") bottom
                        center no-repeat;

                    &:last-child {
                        background: none;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
