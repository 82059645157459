.bem-vindo {
    flex-direction: column;
    align-items: center;

    .container-texto {
        background: url("../img/bgsection.png") #fff 95% 90% no-repeat;
        align-items: center;
        min-height: 25em;

        .texto {
            color: rgb(95,95,83);
            text-align: justify;
            width: 70%;
            line-height: 1.8em;

            h1 {
                margin: 1em 0 1.5em;
                text-transform: uppercase;
                font-weight: normal;
            }
        }

        &.gold {
            flex-direction: column;
            background: rgb(137,137,126);
            position: relative;
            min-height: 35em;
            @include breakpoint($celular) {
                padding: 2em 0;
            }

            .texto {
                color: rgb(255,255,255);

                h2 {
                    text-align: left;
                    font-weight: normal;
                    color: rgb(204,177,124);
                    padding-bottom: 2em;
                    @include breakpoint($celular) {
                        padding-bottom: 1em;
                    }
                }
            }

            &:after {
                content:'';
                width: 100%;
                height: 1em;
                background: linear-gradient(90deg, rgba(204,177,124,1) 0%, rgba(204,177,124,0.1) 80%);
                bottom: 0;
                position: absolute;
            }
        }
    }

    .container {
        .img-home {
            width: 100%;
        }
    }
}
