.footer {
    flex-direction: column;
    align-items: center;
    background-color: rgb(175, 155, 123);
    padding: 4em 0;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @include breakpoint($celular) {
            flex-direction: column;
        }

        img {
            margin-top: 1em;
            @include breakpoint($celular) {
                margin-top: 0;
                margin-bottom: 3em;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            @include breakpoint($tablet) {
                display: none;
            }
            @include breakpoint($celular) {
                display: none;
            }

            li {
                a {
                    color: rgb(95, 95, 83);
                    text-decoration: none;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                    transition: color 0.2s linear;
                    padding: 0.3em 0;
                    display: block;
                    @include breakpoint($celular) {
                        font-size: 1.3em;
                        display: block;
                        padding: 0.4em 0;
                    }

                    &:hover {
                        transition: color 0.2s linear;
                        color: rgb(175, 155, 123);
                    }

                    &.icon {
                        background: rgb(95, 95, 83);
                        border-radius: 50px;
                        width: 30px;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            transition: color 0.2s linear;
                            background: rgb(137, 137, 126);
                        }
                        img {
                            width: 15px;
                            margin: 0;
                        }
                    }
                }

                &.icons {
                    margin-top: 2em;
                    display: flex;
                    justify-content: space-around;
                }
            }
        }

        .infos {
            color: rgb(95, 95, 83);
            text-transform: uppercase;
            letter-spacing: 0.1em;
            line-height: 1.5em;
        }
    }
}
