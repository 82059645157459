.medicos {
    flex-direction: column;
    align-items: center;

    .container-texto {
        background: url("../img/bgsection.png") #fff 95% 90% no-repeat;
        align-items: center;
        min-height: 30em;
        @include breakpoint($celular) {
            padding: 2em 0;
        }

        .texto {
            color: rgb(95, 95, 83);
            text-align: justify;
            width: 70%;
            line-height: 1.8em;

            h1 {
                margin: 1em 0 1.5em;
                text-transform: uppercase;
                font-weight: normal;
                text-align: left;
            }
        }
    }

    .medico {
        position: relative;
        display: flex;
        justify-content: center;

        .bgimg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: 0;
            @include breakpoint($celular) {
                object-fit: cover;
            }
        }

        .content {
            display: grid;
            grid-template-columns: auto auto;
            width: 70%;
            padding-top: 3em;
            z-index: 1;
            @include breakpoint($tablet) {
                width: 90%;
            }
            @include breakpoint($celular) {
                padding-top: 1em;
                width: 90%;
                grid-template-columns: 1fr;
                justify-items: center;
            }

            h2 {
                text-transform: uppercase;
                font-size: 1.4em;
            }

            .texto {
                grid-area: texto;
                color: rgb(96, 95, 84);
                line-height: 1.8em;
                align-self: center;
            }

            img {
                grid-area: imagem;
                align-self: flex-end;
                max-width: 100%;
            }
        }

        &.green {
            background: linear-gradient(
                90deg,
                rgba(198, 198, 190, 1) 0%,
                rgba(204, 204, 204, 1) 100%
            );

            .content {
                grid-template-areas: "texto imagem";
                @include breakpoint($celular) {
                    grid-template-areas: "texto" "imagem";
                }
            }

            .texto {
                text-align: right;
                @include breakpoint($celular) {
                    text-align: center;
                }
            }
        }

        &.gold {
            background: linear-gradient(
                90deg,
                rgba(175, 155, 123, 0.7) 0%,
                rgba(175, 155, 123, 1) 100%
            );

            .content {
                grid-template-areas: "imagem texto";
                @include breakpoint($celular) {
                    grid-template-areas: "texto" "imagem";
                }
            }

            .texto {
                text-align: left;
                @include breakpoint($celular) {
                    text-align: center;
                }
            }
        }
    }
}
