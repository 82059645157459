.header {
    background: rgb(95,95,83);
    padding: 3em 0 1.5em;
    color: rgb(175,155,123);
    position: fixed;
    z-index: 9999;
    width: 100%;
    top: 0;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include breakpoint($celular) {
            flex-direction: column;
            align-items: flex-start;
        }

        .logo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include breakpoint($celular) {
                width: 100%;
                align-items: flex-end;
            }

            button, button:focus {
                display: none;
                background: transparent;
                border: none;
                outline: 0;
                @include breakpoint($celular) {
                    display: block;
                    height: 29px;
                }
            }
        }

        ul {
            list-style: none;
            padding: 0;
            display: flex;
            @include breakpoint($celular) {
                flex-direction: column;
                width: 100%;
                margin: 1em 0 0;
                display: none;
            }

            li {
                @include breakpoint($celular) {
                    border-bottom: solid 1px rgba(175, 155, 123, .7);
                }

                &:after {
                    content: '|';
                    @include breakpoint($tablet) {
                        margin: 0 .5em 0 .1em;
                    }
                    @include breakpoint($celular) {
                        content: '';
                        margin: 0;
                    }
                    margin: 0 1.2em 0 1em;
                }

                &:last-child {
                    &:after {
                        content: '';
                    }
                    @include breakpoint($celular) {
                        border-bottom: none;
                    }
                }

                a {
                    color: rgb(175,155,123);
                    text-decoration: none;
                    text-transform: uppercase;
                    letter-spacing: .2em;
                    transition: color .2s linear;
                    @include breakpoint($celular) {
                        font-size: 1.3em;
                        display: block;
                        padding: .4em 0;
                    }

                    &:hover {
                        transition: color .2s linear;
                        color: rgb(204,177,124);
                    }
                }
            }
        }
    }
}
