.hospedagem {
    flex-direction: column;
    align-items: center;

    .container-texto {
        background: url("../img/bgsection.png") #fff 95% 90% no-repeat;
        align-items: center;
        min-height: 25em;
        @include breakpoint($celular) {
            padding: 2em 0;
        }

        .texto {
            color: rgb(95, 95, 83);
            text-align: justify;
            width: 70%;
            line-height: 1.8em;

            h1 {
                margin: 0 0 1.5em;
                text-transform: uppercase;
                font-weight: normal;
                text-align: left;
            }
        }
    }
}
