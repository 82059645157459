.quartos {
    flex-direction: column;
    align-items: center;
    position: relative;

    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: rgb(164, 174, 152);
        padding: 3em 0 6em;
        position: relative;

        .instalacoes {
            width: 90%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 1em;
            @include breakpoint($celular) {
                grid-template-columns: repeat(2, 1fr);
            }

            img {
                width: 100%;
                height: 150px;
                object-fit: cover;
                border: solid 2px rgb(95, 95, 83);
                @include breakpoint($full) {
                    height: 12em;
                }
                @include breakpoint($tablet) {
                    height: 110px;
                }
                @include breakpoint($celular) {
                    height: 110px;
                }
            }
        }

        .quartos-info {
            width: 90%;
            margin-top: 6em;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1em;
            @include breakpoint($celular) {
                grid-template-columns: 1fr;
            }

            .info {
                padding-right: 5em;
                @include breakpoint($tablet) {
                    padding-right: 2em;
                }
                @include breakpoint($celular) {
                    padding-right: 0em;
                }

                ul {
                    li {
                        text-transform: uppercase;
                        color: rgb(255, 255, 255);
                        margin-bottom: 2em;
                        font-size: 1.2em;
                        @include breakpoint($tablet) {
                            font-size: 1.1em;
                        }
                        @include breakpoint($celular) {
                            font-size: 1.1em;
                        }
                    }
                }
                h1 {
                    color: rgb(95, 95, 83);
                    margin: 0 1.2em 1.5em;
                    text-transform: uppercase;
                    font-weight: normal;
                }
            }

            .imagens {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 1em;

                img {
                    width: 100%;
                    height: 150px;
                    object-fit: cover;
                    border: solid 2px rgb(95, 95, 83);
                    filter: grayscale(1);
                    transition: filter 0.4s linear;
                    @include breakpoint($full) {
                        height: 12em;
                    }
                    @include breakpoint($tablet) {
                        height: 110px;
                    }
                    @include breakpoint($celular) {
                        height: 110px;
                    }

                    &:hover {
                        transition: filter 0.4s linear;
                        filter: grayscale(0);
                    }
                }

                &.nutricao {
                    a:first-child {
                        grid-column: 1/3;

                        img {
                            height: 300px;
                            @include breakpoint($tablet) {
                                height: 200px;
                            }
                            @include breakpoint($celular) {
                                height: 250px;
                            }
                        }
                    }
                }
            }
        }

        &:after {
            content: "";
            width: 100%;
            height: 1em;
            background: linear-gradient(
                90deg,
                rgba(204, 177, 124, 1) 0%,
                rgba(204, 177, 124, 0.1) 80%
            );
            bottom: 0;
            position: absolute;
        }
    }
}
