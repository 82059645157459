@font-face {
    font-family: "Humanst521 BT";
    src: url("../fonts/Humanist521BT-Bold.woff2") format("woff2"),
        url("../fonts/Humanist521BT-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Humanst521 BT";
    src: url("../fonts/Humanist521BT-Roman.woff2") format("woff2"),
        url("../fonts/Humanist521BT-Roman.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$celular: max-width 767px;
$tablet: 768px 1199px;
$monitor: 1200px 1439px;
$full: min-width 1440px;

@import "node_modules/breakpoint-sass/stylesheets/breakpoint";

* {
    font-family: "Humanst521 BT", sans-serif;
}
body,
html {
    background-color: rgb(230, 230, 230);
    min-height: 100vh;
}

.flex {
    display: flex;
    justify-content: center;
}

.container {
    @include breakpoint($full) {
        width: 70%;
    }
    @include breakpoint($monitor) {
        width: 80%;
    }
    @include breakpoint($tablet) {
        width: 90%;
    }
    @include breakpoint($celular) {
        width: 90%;
    }
}

@import "partials/header";
@import "partials/banner";
@import "partials/bem-vindo";
@import "partials/medicos";
@import "partials/equipe";
@import "partials/quartos";
@import "partials/hospedagem";
@import "partials/atividades";
@import "partials/footer";
